import i18n from "@/libs/i18n";
import router from "@/router";

export default {
    async get({commit}, data) {
        commit('loadingStart', null, {root: true});

        const filters = window.mapFilterData(data);

        await this._vm.$http
            .get(`products${filters}`)
            .then(respo => {
                if (respo.status === 200) {

                    setTimeout(()=>{
                        commit('setData', respo.data.data);
                    },0);


                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },
    async detail({commit}, id) {
        commit('loadingStart', null, {root: true});

        await this._vm.$http
            .get(`products/${id}`)
            .then(respo => {
                if (respo.status === 200) {
                    commit('setDetail', respo.data.data);

                    commit('loadingFinish', null, {root: true});

                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },
    async unsetDetail({commit}) {
        commit('unsetDetail');
    },


    async reorder({commit}, data) {
        commit('loadingStart', null, {root: true});
        await this._vm.$http
            .post(`products/reorder/${data.id}`, data)
            .then(respo => {
                if (respo.status === 200) {
                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {

                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },

    async toggle({commit}, data) {
        commit('loadingStart', null, {root: true});


        await this._vm.$http
            .post(`products/toggle`, data)
            .then(respo => {
                if (respo.status === 200) {

                    commit('successMessage', 'toggle', {root: true});
                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('failMessage', 'toggle', {root: true});
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },

    async create({commit}, data) {
        commit('loadingStart', null, {root: true});
        await this._vm.$http
            .post('products/create', data)
            .then(respo => {
                if (respo.status === 200) {
                    router.push({name: 'products', params: {lang: i18n.locale}});
                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },

    async update({commit}, data) {
        commit('loadingStart', null, {root: true});
        await this._vm.$http
            .post(`products/update/${data.id}`, data)
            .then(respo => {
                if (respo.status === 200) {
                    router.push({name: 'products', params: {lang: i18n.locale}});
                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },

    async delete({commit}, id) {
        commit('loadingStart', null, {root: true});
        await this._vm.$http
            .post(`products/delete/${id}`)
            .then(respo => {
                if (respo.status === 200) {
                    commit('successMessage', 'delete', {root: true});
                    commit('loadingFinish', null, {root: true});

                    commit('deleteItem', id);
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },

    async getCategories({commit}) {

        await this._vm.$http
            .get(`products/categories`)
            .then(respo => {
                if (respo.status === 200) {
                    commit('setCategories', respo.data.data);

                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },

};
